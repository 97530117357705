//
// Variable Includes Partial
//
// @package     Prismarine
// @subpackage  Base
// @author      Very - Engineer Esteban Rocha
// @link        https://verypossible.com
//
// @since       1.0.0
//

// Imports
@import "color/color-palette"; // Theme colors.
@import "typography/font-palette"; // Theme color classes.

// Dark Matter Customization
$sectionMaxWidth: 90rem;
