//
// Color Classes Component
//
// @package     Prismarine
// @subpackage  Base
// @author      Very - Engineer Esteban Rocha
// @link        https://verypossible.com
//
// @since       1.0.0
//

//
// Color Functional CSS Classes
//
.colr {
	&-main-color {
		color: var(--main-color);
	}

	&-white {
		color: var(--white);
	}

	&-black {
		color: var(--black);
	}

	&-purple {
		color: var(--purple);
	}
}
