//
// Typography Component
//
// @package     Prismarine
// @subpackage  Base
// @author      Very - Engineer Esteban Rocha
// @link        https://verypossible.com
//
// @since       1.0.0
//

// Harmonia Sans Pro
.setHarmonia {
	&-regular {
		font: {
			family: var(--harmonia);
			style: normal;
			weight: 400;
		}
	}

	&-bold {
		font: {
			family: var(--harmonia);
			style: normal;
			weight: 700;
		}
	}
}

// Platform
.setPlatform {
	&-medium {
		font: {
			family: var(--platform);
			style: normal;
			weight: 500;
		}
	}

	&-regular {
		font: {
			family: var(--platform);
			style: normal;
			weight: 400;
		}
	}
}
