//
// Standard Css Reset Component
//
// @package     Prismarine
// @subpackage  Base
// @author      Very - Engineer Esteban Rocha
// @link        https://verypossible.com
//
// @since       1.0.0
//

:root {
	@include theme-colors;
	@include theme-fonts;
}

html {
	box-sizing: border-box;
	/* stylelint-disable-next-line */
	font-size: 16px; // Explicit declare REM's
	scroll-behavior: smooth;
	tab-size: 4; // 4 //
	-webkit-tap-highlight-color: transparent; //4. Remove the grey highlight on links in iOS (opinionated).
	/* stylelint-disable-next-line */
	-ms-text-size-adjust: 100%; // 5 Prevent adjustments of font size after orientation
	// changes in IE on Windows Phone and in iOS.
	/* stylelint-disable-next-line */
	-webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
	// Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ //
	box-sizing: inherit;
}

::before,
::after {
	text-decoration: inherit; // 1. Add text decoration inheritance in all browsers (opinionated).
	vertical-align: inherit; // 2. Add vertical alignment inheritance in all browsers (opinionated).
}

* {
	&:focus {
		outline: 0;
	}
}

body {
	// @include crosshair;
	background: {
		color: var(--white); // Fallback for when there is no custom background color defined. //
		position: 50% 0%;
		repeat: no-repeat;
		size: cover;
	}
	color: var(--black);
	font: {
		family: var(--system-fonts);
		/* stylelint-disable-next-line */
		size: 16px;
		style: normal;
		weight: normal;
	}
	margin: 0;

	&.-hidden {
		background-color: var(--white);
		/* stylelint-disable-next-line */
		background-image: none !important;
	}
}

// W3C Headings Recommendation Standard
h1 {
	font-size: 2rem;
}

h2 {
	font-size: 1.5rem;
}

h3 {
	font-size: 1.17rem;
}

h4 {
	font-size: 1rem;
}

h5 {
	font-size: 0.83rem;
}

h6 {
	font-size: 0.67rem;
}

pre,
pre > *,
code,
code > * {
	font-family: var(--system-fonts-mono);
	font-size: 1rem;
	line-height: 1.2;
}

// Remove the list style on navigation lists in all browsers (opinionated).
nav ol,
nav ul {
	list-style: none;
	padding: 0;
}

a {
	color: inherit;
	text: {
		decoration: none;
	}

	&:hover {
		text: {
			decoration: none;
		}
	}
}

button {
	appearance: none;
	border: 0;
}

textarea {
	resize: none;
}

//Select
// a,
// br,
// div,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// label,
// p,
// span,
// strong,
// i,
// em {
// 	&::selection {
// 		background-color: transparent;
// 		color: #846caa;
// 	}
// }

img {
	height: auto; // Make sure images are scaled correctly. //
	max-width: 100%; // Adhere to container width. //
}

figure {
	margin: 0; // no need or want margins on figures

	img {
		display: block;
	}
}

hr {
	border: 0.5px solid var(--black);
	height: 0;
	margin: 0;
	max-width: 100%;
}

table {
	border-collapse: collapse;
}

// Customize scrollbar for webkit browsers, Chrome <-> Safari
$scrollIndex: 99;
$scrollTickness: 8px;

::-webkit-scrollbar {
	height: $scrollTickness; // for horizontal scrollbars //
	width: $scrollTickness; // for vertical scrollbars //
	z-index: $scrollIndex;
}

::-webkit-scrollbar-track {
	background: #eeeeee;
	z-index: $scrollIndex;
}

::-webkit-scrollbar-thumb {
	background: #626262;
	z-index: $scrollIndex;
}

//mindbody modules
.listContainer {
	background-color: rgba(255, 255, 255, 0.7);
	padding: 2rem;
	width: 100%;
	max-width: 100%;
	min-width: 100%;

	.controls {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 0.5rem;

		p {
			width: 100%;
			margin-top: 0;
			font-family: var(--harmonia);
			font-size: 1.125rem;
		}

		.dates {
			position: relative;

			svg {
				position: absolute;
				right: 0.75rem;
				top: 0.75rem;
				pointer-events: none;
			}
		}

		.dates,
		.instructors,
		.types {
			flex-grow: 1;
			position: relative;
			margin-right: 0.5625rem;

			.svgChevron {
				position: absolute;
				top: 0.75rem;
				right: 0.75rem;
				pointer-events: none;
			}

			input,
			select {
				width: 100%;
				padding: 0.75rem;
				height: 3.5rem;
				border: 0;
				border-radius: 0;
				font-family: var(--platform);
				font-size: 1.375rem;
				color: var(--dark-grey);
				appearance: none;
				cursor: pointer;
			}

			select {
				color: var(--gray-60);
			}
		}

		.types {
			margin-right: 0;
		}
	}

	.listItem {
		background-color: #f8f8f8;
		display: flex;
		flex-wrap: wrap;
		margin: 0.5rem 0;
		padding: 0.75rem;
		max-width: 100%;
		width: 100%;
		position: relative;

		.blur {
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			width: 100%;
			height: 100%;
			background-color: rgba(255, 255, 255, 0.5);
			backdrop-filter: blur(3.5px);
		}

		.image {
			width: 6rem;
			height: 6.5rem;
			background-size: 80%;
			background-position: center;
			background-repeat: no-repeat;
			background-color: #f4efea;
		}

		.details {
			flex-grow: 1;
			margin: 0 1.25rem;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			width: 50%;

			.seriesMembershipPrice {
				font-family: var(--harmonia);
				font-weight: 700;
				font-size: 1.125rem;
				color: #1b2530;
				margin: 0.5rem 0;
			}

			.classDate,
			.seriesMembershipDate {
				font-family: var(--harmonia);
				font-weight: 700;
				font-size: 1.25rem;
				color: var(--dark-grey);
			}

			.className,
			.seriesMembershipName {
				font-family: var(--platform);
				color: var(--blue);
				font-size: 1.25rem;
				margin: 0.5rem 0;
				font-weight: 500;
			}

			.classLength,
			.seriesMembershipLength {
				font-family: var(--harmonia);
				font-size: 1.25rem;
				color: var(--dark-grey);

				/* stylelint-disable-next-line */
				span {
					color: var(--gray-60);
				}
			}

			.className,
			.classDate,
			.classLength,
			.classDescription,
			.seriesMembershipName,
			.seriesMembershipDate,
			.seriesMembershipLength,
			.seriesMembershipDescription,
			.seriesMembershipPrice {
				width: 100%;
			}
		}

		.modalTrigger,
		.enroll,
		.modalBtn {
			font-family: var(--harmonia);
			font-size: 1.125rem;
			color: var(--orange);
			background-color: #f4efea;
			width: 8.875rem;
			height: 4.25rem;
			display: flex;
			align-items: center;
			padding: 0.75rem;
			justify-content: space-between;
			cursor: pointer;
			margin: {
				top: auto;
				bottom: auto;
			}
		}
	}

	.seriesMembershipList {
		display: flex;
		flex-direction: column;
		min-height: 25rem;
		margin-top: 1rem;

		.listItemService {
			margin-top: 0;
		}

		.image {
			width: 12.5rem;
			height: 9.8125rem;
			background-size: 100%;
			background-color: transparent;
		}

		.modalTrigger,
		.enroll,
		.modalBtn {
			margin-top: auto;
			margin-bottom: 0;
		}
	}

	.listItemService {
		align-items: center;
		display: flex;
		padding: 0.75rem;
		background-color: #f9f9f9;
		margin: {
			top: 1rem;
			bottom: 1rem;
		}
	}

	.itemDataService {
		margin-right: auto;

		.name {
			font-family: var(--harmonia);
			font-weight: 700;
			color: #1b2530;
			font-size: 1.125rem;
		}

		.expiration {
			font-family: var(--harmonia);
			font-weight: 700;
			color: #bbbec1;
			font-size: 1.25rem;
		}
	}

	.itemPriceService {
		color: #445590;
		font-family: var(--harmonia);
		font-size: 1.125rem;
		margin-right: 2.5rem;
		font-weight: 700;
	}

	.itemPurchase, .purchaseBtn {
		background-color: #f4efea;
		width: 8.875rem;
		color: var(--orange);
		padding: 0.75rem;
		font-size: 1.125rem;
		height: 4.25rem;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
}

.controlArrows {
	width: 10.75rem;

	button {
		appearance: none;
		background-color: var(--orange);
		color: var(--white);
		padding: 1.1875rem 1.375rem;
		border: 0;
		margin: 0 -0.125rem;
		cursor: pointer;

		&:hover {
			opacity: 0.5;
		}

		.svgArrow {
			path {
				fill: var(--white);
			}
		}
	}

	button:disabled,
	button[disabled] {
		background-color: #f4efea;

		.svgArrow {
			path {
				fill: var(--orange);
			}
		}
	}
}

#modal {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;

	&.active {
		display: block;
	}

	&::after {
		content: "";
		display: block;
		position: fixed;
		z-index: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		top: 0;
		left: 0;
		backdrop-filter: blur(10px);
	}

	.modal {
		background-color: #ffffff;
		display: flex;
		flex-direction: column;
		width: 90%;
		max-width: 67.5rem;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		position: absolute;
		max-height: 44.625rem;

		.formHolder {
			max-height: 30.625rem;
			overflow-y: scroll;
			width: 100%;
		}

		.close {
			cursor: pointer;
		}

		.modalHeader {
			height: 4.375rem;
			background: #f8f8f8;
			font-family: var(--harmonia);
			font-size: 1.125rem;
			font-weight: 700;
			display: flex;
			flex-direction: column;
			padding: 2rem;
			width: 100%;
		}

		.modalFooter {
			display: flex;
			width: 100%;
			padding-top: 2rem;
		}

		.modalDetails {
			background-color: #f8f8f8;
			padding: 2rem;
			display: flex;
			height: 10.5rem;
			max-height: auto;

			.image {
				width: 6rem;
				height: 6.5rem;
				background-size: 80%;
				background-position: center;
				background-repeat: no-repeat;
				background-color: #f4efea;
			}

			.details {
				flex-grow: 1;
				margin: 0 1.25rem;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				margin-bottom: 1.4375rem;
			}

			.classDate {
				font-family: var(--harmonia);
				font-weight: 700;
				font-size: 1.25rem;
				color: var(--dark-grey);
			}

			.className {
				font-family: var(--platform);
				color: var(--blue);
				font-size: 1.25rem;
				margin: 0.5rem 0;
				font-weight: 500;
			}

			.classLength {
				font-family: var(--harmonia);
				font-size: 1.25rem;
				color: var(--dark-grey);

				/* stylelint-disable-next-line */
				span {
					color: var(--gray-60);
				}
			}

			.className,
			.classDate,
			.classLength {
				width: 100%;
			}
		}

		.modalDescription {
			background-color: var(--white);
			padding: 2rem;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
		}
	}

	.modalItem {
		.classDescription {
			width: 100%;
			margin-bottom: 2rem;
		}

		.purchase,
		.servicePurchase,
		.contractPurchase {
			font-family: var(--harmonia);
			font-size: 1.125rem;
			color: var(--orange);
			background-color: #f4efea;
			width: 8.875rem;
			height: 4.25rem;
			display: flex;
			align-items: center;
			padding: 0.75rem;
			justify-content: space-between;
			cursor: pointer;
			margin: {
				top: auto;
				left: auto;
			}
		}
	}

	.registerNotice {
		display: flex;
		align-items: center;
		padding: 32px;
		max-width: 34.125rem;
		width: 70%;
		margin-right: auto;
		font-size: 0.75rem;
		font-family: var(--harmonia);
		color: #5f666e;
	}

	.loginForm,
	.registerForm {
		display: flex;
		align-items: center;
		justify-content: center;

		h1 {
			font-family: var(--platform);
			font-size: 2.5rem;
			font-weight: 400;
		}

		h3 {
			font-size: 1.125rem;
			font-family: var(--harmonia);
			font-style: 700;
			margin: {
				top: 2rem;
				bottom: 1.5rem;
			}
		}

		form {
			width: 100%;
			max-width: 18.5rem;
			margin: 0 auto;
		}

		label {
			display: block;
			font-family: var(--harmonia);
			font-size: 1rem;
			margin-bottom: 0.375rem;
		}

		input,
		select {
			&:not([type="checkbox"]) {
				max-width: 100%;
				appearance: none;
				border-radius: 0.25rem;
				border: 1.5px solid #bbbec1;
				height: 2.8125rem;
				width: 100%;
				padding: 0.75rem;
			}
		}

		.button {
			font-family: var(--harmonia);
			color: var(--orange);
			font-size: 1rem;
			width: 9.75rem;
			background-color: #f4efea;
			margin-bottom: 1.5rem;
			margin: {
				left: auto;
				right: auto;
			}
			height: 3.1875rem;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			margin-top: 2rem;

			&.link {
				margin-top: 0;
				background-color: transparent;
			}

			&.arrow {
				padding: 0.75rem;
				justify-content: space-between;
				margin: {
					left: auto;
					right: 2rem;
					top: 3.375rem;
				}
			}
		}
	}

	.registerForm {
		form {
			max-width: 34.375rem;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}

		.button.arrow {
			height: auto;
			margin-top: auto;
		}

		p:nth-child(1),
		p:nth-child(2),
		p:nth-child(13),
		p:nth-child(14),
		p:nth-child(16),
		p:nth-child(17),
		p:nth-child(18),
		p:nth-child(19) {
			width: 47%;
		}

		p:nth-child(6),
		p:nth-child(7),
		p:nth-child(8) {
			width: 30.3%;
		}

		p {
			width: 100%;
			margin: 0.75rem 1.5%;
		}
	}

	.loginErrors {
		text-align: center;
		font-size: 0.875rem;
	}
}

.classList,
.seriesMembership {
	min-height: 31.25rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	position: relative;
}

.classList {
	#closeModal {
		display: none;
	}
}

.seriesMembership {
	.listContainer {
		.controls {
			.dates {
				margin-right: 0;
			}
		}
	}
}

.serviceList {
	h3 {
		padding: 2rem 2rem 0;
	}

	.list {
		overflow-y: scroll;
		max-height: 27.625rem;
		padding: 0 2rem;
		margin-bottom: 3rem;

		.listItem {
			align-items: center;
			display: flex;
			padding: 0.75rem;
			background-color: #f9f9f9;
			margin: {
				top: 1rem;
				bottom: 1rem;
			}
		}

		.itemData {
			margin-right: auto;

			.name {
				font-family: var(--harmonia);
				font-weight: 700;
				color: #1b2530;
				font-size: 1.125rem;
			}

			.expiration {
				font-family: var(--harmonia);
				font-weight: 700;
				color: #bbbec1;
				font-size: 1.25rem;
			}
		}

		.itemPrice {
			color: #445590;
			font-family: var(--harmonia);
			font-size: 1.125rem;
			margin-right: 2.5rem;
			font-weight: 700;
		}

		.itemPurchase {
			background-color: #f4efea;
			width: 8.875rem;
			color: var(--orange);
			padding: 0.75rem;
			font-size: 1.125rem;
			height: 4.25rem;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
	}
}

.loading {
	overflow: hidden;
	transition: all 0.25s ease-in-out;
	position: relative;

	&::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: "";
		background-color: rgba(255, 255, 255, 0.85);
		background-image: url("/wp-content/themes/prismarine/assets/loading-.svg");
		background-repeat: no-repeat;
		background-position: center;
	}
}

.purchaseSuccess {
	.purchaseSuccessContent {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 2rem 0 4rem;
	}

	h2 {
		font-family: var(--platform);
		font-size: 2.5rem;
		margin: 2rem 0;
		font-weight: 400;
	}
}
