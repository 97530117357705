//
// Transitions Mixing
//
// @package     Prismarine
// @subpackage  Mixins
// @author      Very - Engineer Esteban Rocha
// @link        https://verypossible.com
// @since       1.0.0
//
//

@mixin transitions($properties, $timingFunc: "0.6s ease 0s", $separator: ",") {
	// Declare utility variables
	$transitions: null;
	$transient: null;
	$timing: null;

	// Set transition duration, timing-function and delay
	$timing: append(#{$timingFunc}, #{$separator});

	// Loop through the number of props and create a list with all transitions
	@each $property in $properties {
		// Skip the separator on the last loop cycle
		@if $property == nth($properties, -1) {
			$timing: #{$timingFunc};
		}

		$transient: append(#{$property}, #{$timing});
		$transitions: join(#{$transitions}, #{$transient});
	}

	// "Return"
	transition: $transitions;
}
