//
// Color Palette Component
//
// @package     Prismarine
// @subpackage  Base
// @author      Very - Engineer Esteban Rocha
// @link        https://verypossible.com
//
// @since       1.0.0
//

// Theme Colors
@mixin theme-colors {
	--main-color: #000;
	--black: #000;
	--light-gray: #acb6b8;
	--white: #fff;

	--orange: #e26435;
	--gray: #bbbec1;
	--darkish-gray: #5f666e;
	--darkish-gray-80: #5e666f;
	--gray-60: #8d9297;
	--grey-60: #8c9298;
	--cream: #f4efea;
	--cream-110: #fef3e9;
	--cream-120: #e8e9ea;
	--dark-grey: #1b2530;
	--blue: #445590;
	--blue-20: #dadde9;
}
